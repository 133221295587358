export const enTranslations = {
    tabs: {
        shop_all: "Shop All",
        product: "Product",
        cart: "Cart",
        checkout: "Checkout",
        order_lookup: "Order Lookup",
        order_history: "My Orders",
        order_details: "Order Details",
        start_a_return: "Start a Return"
    },
    common: {
        final_sale: "Final Sale",
        no_image: "No Image",
        or: "or",
        sold_by: "Sold by"
    },
    errors: {
        out_of_stock: "Out of Stock",
        variant_not_available: "Not Available. Please choose another option.",
        line_item_is_not_available: "Item is not available",
        reduce_qty: "Sorry, only {{qty}} is available. Please reduce your QTY.",
        inventory_alert: "Sorry, an item(s) in your cart is no longer available. Please update the item(s) to proceed.",
        email_required: "Email Address is required",
        email_invalid: "Please enter a valid Email Address",
        first_name_required: "First Name is required",
        last_name_required: "Last Name is required",
        address_required: "Street Address is required",
        city_required: "City is required",
        state_required: "State is required",
        state_is_not_supported: "Continental U.S. only",
        zip_code_required: "Please enter your Zip Code",
        zip_code_invalid: "Please enter a valid Zip Code",
        applepay_zipcode_invalid: "Zip code is invalid",
        applepay_state_invalid: "Sorry, we are only shipping to the 48 continental U.S. States.",
        applepay_country_invalid: "Sorry, we are unable to ship outside the United States.",
        applepay_name_invalid: "Sorry, name must be less than 255 characters.",
        applepay_phone_invalid: "Please enter a valid 10-11 digits Phone Number",
        phone_number_required: "Phone Number is required",
        phone_number_invalid: "Please enter a valid Phone Number",
        card_number_required: "Please enter a valid credit card number",
        card_number_invalid: "Please enter a valid credit card number",
        exp_date_required: "Please enter a valid expiration date",
        exp_date_invalid: "Please enter a valid expiration date",
        cvv_required: "CVV is required",
        cvv_invalid: "Please enter a valid CVV",
        cart_inventory_alert: "Sorry, an item(s) in your cart is no longer available. Please update the item(s) to proceed.",
        generic_alert: "Whoops, looks like something went wrong. Try again",
        invalid_zip_code_alert: "Please enter a valid zip code",
        payment_transaction_declined_alert: "Your payment was declined. Please check your information and try again or contact your financial institution.",
        invalid_state: "Sorry, we are unable to ship to {{state}}. We are only shipping to the 48 continental U.S. States.",
        paypal_place_order: "Your PayPal payment was declined. Please check your information and try again or contact customer service for assistance.",
        price_has_changed: '"{{name}}" product price has been changed. Please remove it from the cart and add it again',
        cart_has_expired: "Sorry your shopping cart has been expired. Click to clear cart and continue shopping. ",
        invalid_address_info: "Billing/Shipping address details are not fully specified.",
        sandbox_mode: "SANDBOX MODE ({{env}})",
        inventory_out_of_stock_error: "There are not enough products in stock"
    },
    pdp: {
        sold_by: "Sold by",
        quantity: "Quantity",
        quantity_v3: "Qty",
        qty: "Qty",
        free_standard_shipping: "Free Standard Shipping",
        free_standard_shipping_description: "Free Standard shipping only applies to the 48 continental U.S. States.",
        free_standard_shipping_description_excludes: "Excludes Alaska, Hawaii, American Samoa, U.S. Virgin Islands, Northern Mariana Islands, Guam, and Puerto Rico",
        "30_days_return": "30 Days Return",
        "30_days_return_description_p1": "Items are eligible",
        "30_days_return_description_p2": "for return within 30 days of purchase*",
        "30_days_return_description_p3": "provided they meet the",
        "30_days_return_description_p4": "Guidelines",
        "30_days_return_description_return_policy_ref_text": "Returns Policy",
        final_sale: "Final Sale",
        final_sale_description: "Final sales are NOT eligible for returns or exchanges.",
        final_sale_description_return_policy_ref_text: "View Policy",
        product_description: "Product Description",
        add_to_cart: "Add to Cart",
        qty_limit: "Limit {{qty}} per customer",
        back_in_stock: "Notify Me When Back In Stock",
        notify_me: "Notify me",
        please_select: "Please select",
        option: "option",
        select: "Select",
        item_added_to_cart: "Item added to cart.",
        your_item_added_to_cart_v3: "Your item was added to the cart",
        view_checkout: "Checkout",
        view_cart: "View Cart",
        continue_shopping: "Continue Shopping",
        oops: "Oops!",
        not_found: "We can't seem to find the page you're looking for",
        try_smth_else: "We are on it! In the mean time, please feel free to browse other content. Thank you for your patience",
        price: "Price",
        see_more: "Load More",
        custom_attribute_max_length_error: "{{maxLength}} characters max.",
        custom_attribute_valid_characters: "Please use valid characters",
        custom_attribute_empty_error: "Value is required",
        click_to_zoom: "click to zoom",
        pinch_to_zoom: "pinch to zoom"
    },
    cart: {
        empty_cart: "Your cart is empty.",
        empty_cart_description: "Looks like you haven’t added any items to your cart yet.",
        start_shopping: "Start Shopping",
        sku: "SKU",
        subtotal: "Subtotal",
        shipping: "Shipping",
        shipping_description: "Shipping only to the 48 continental U.S. States.",
        shipping_exclude_states: "Excludes Alaska, Hawaii, American Samoa, U.S. Virgin Islands, Northern Mariana Islands, Guam, and Puerto Rico.",
        taxes: "Taxes",
        tax: "Tax",
        taxes_estimate: "Estimate",
        zip_code: "Zip code",
        zip_code_input_placeholder: "Enter Zip Code",
        apply_button_text: "apply",
        total: "Total",
        estimated_total: "Estimated Total",
        checkout: "Checkout",
        zip_code_required: "Please enter your Zip Code",
        zip_code_invalid: "Please enter a valid Zip Code"
    },
    checkout: {
        shipping_tbd: "TBD",
        shipping_free: "Free",
        order_summary: "Order Summary",
        email: "Email Address",
        subscribe_to_newsletter_first: "Share my email with ",
        subscribe_to_newsletter_retailer: "retailers",
        subscribe_to_newsletter_second: " of items in my cart to receive ",
        subscribe_to_newsletter_third: "promotions & exclusive content ",
        subscribe_to_newsletter_fourth: "from them.",
        shipping_address: "Shipping Address",
        save_as_primary_address: "Save as your primary address?",
        first_name: "First Name",
        last_name: "Last Name",
        address: "Address",
        apt_suite_floor: "Apt/Suite/Floor (Optional)",
        city: "City",
        state: "State",
        zip_code: "Zip Code",
        phone_number: "Phone number",
        shipping_method: "Shipping Method",
        shipment: "Shipment {{index}} of {{total}}",
        save_payment_info: {
            title: "Save Payment Info",
            label: "Save my payment information for a faster checkout"
        },
        update_payment_method: "Update as primary payment method?",
        update_billing_address: "Update as primary billing address?",
        shipped_by: "Shipped by",
        multiple_shipment: "Multiple Shipments",
        shipping_method_std: "Standard Delivery (3-5 days)",
        shipping_method_std_label: "Standard",
        shipping_method_exp: "Express Delivery (1-2 days)",
        shipping_method_exp_label: "Express",
        shipping_method_nex: "Next day delivery (ordered before 5pm EST)",
        shipping_method_nex_label: "Next Day Delivery",
        shipping_method_pro: "Estimated shipment in May 2023",
        shipping_method_pro_label: "Preorder",
        shipping_method_2dy: "2 Day Shipping",
        shipping_method_2dy_label: "Two Day",
        shipping_method_10bd: "Standard delivery (10 business days)",
        shipping_method_10bd_label: "TODAY Deals: Standard",
        shipping_method_std_blue_acorn: "Standard delivery (1-2 weeks)",
        shipping_method_std_blue_acorn_label: "Standard",
        shipping_method_bo_label: "Backorder",
        shipping_method_default: "Description not found",
        shipping_method_default_label: "No label",
        payment: "Payment",
        card_number: "Card Number",
        expiration_date: "Exp:MM/YY",
        cvv: "CVV",
        payment_method: "Payment Method",
        billing_address: "Billing Address",
        use_same_shipping_address: "Use same as shipping address",
        use_same_shipping_address_v3: "Billing address is the same as shipping",
        credit_card_security_code: "Credit Card Security Code",
        visa_mastercard_discover: "Visa, Mastercard, Discover",
        cvv_explanation_1: "The last 3-digits printed on the back of the card is the security code.",
        american_express: "American Express",
        cvv_explanation_2: "The 4-digit security code is printed on the front of the card.",
        expiration_date_label: "Exp",
        charged: "Charged",
        summary: "Summary",
        continue: "Continue",
        continue_to_shipping: "Continue To Shipping",
        continue_to_payment: "Continue To Payment",
        place_order: "Place Order",
        placing_order_agree: "By placing your order you agree to the",
        review_order: "Review Order",
        consent_message_checkout_method: "By selecting a method,",
        consent_message_first: "I agree to the",
        consent_message_and: "and",
        consent_message_terms_of_use: "Terms of Use",
        consent_message_privacy_policy: "Privacy Policy",
        sailthru_consent_message: "I accept that NBCUniversal may send me marketing messages.",
        transaction_processing_first: "Please wait while we process your transaction",
        transaction_processing_second: "Do not hit back or refresh",
        customer_information: "Customer Information",
        edit: "Edit",
        remove: "Remove",
        credit_debit_card: "Credit/Debit Card",
        checkout_method: "Checkout Method"
    },
    thank_you: {
        thank_you: "Thank you.",
        your_order_has_been_placed: "Your order has been placed.",
        order_number: "Order Number",
        confirmation_email_description: "A confirmation email has been sent to you. If the email hasn’t arrived within two minutes, please check your spam folder to see if the email was routed there."
    },
    footer: {
        customer_service: "Customer Service",
        track_my_order: "Track My Order",
        shipping_and_returns: "Shipping & Returns",
        start_a_return: "Start a Return",
        faq: "FAQs",
        customer_terms_and_conditions: "Terms & Conditions",
        privacy_policy: "Privacy Policy"
    },
    order_lookup: {
        title: "Order Lookup",
        description: "To begin enter your email address",
        submit_button_text: "Submit",
        check_your_email: "Check your inbox",
        email: "Email",
        post_submit_msg_1: "Your order history has been emailed to you. Click on the link within your email to manage your orders.",
        post_submit_msg_2: "If the email hasn’t arrived within two minutes. Please check your spam folder to see if the email was routed there."
    },
    start_a_return: {
        title: "Start a Return"
    },
    order_history: {
        no_orders_title: "No Orders Found",
        no_orders_description: "We couldn't find any orders under the email you provided",
        no_orders_confirmation: "Please confirm you are entering the right email and try again or contact customer service for assistance.",
        send_another_link: "Send mе another link",
        my_orders_title: "My orders",
        track: "Track",
        track_order: "Track Order",
        return: "Return",
        powered_by: "Powered by",
        order_details: "Order details",
        track_message_not_available: "Tracking Number is not available",
        track_message_will_be_available: "Tracking will be available once Shipped",
        order_status_open: "Open",
        order_status_shipped: "Shipped",
        order_status_processing: "Processing",
        order_status_completed: "Completed",
        order_status_cancelled: "Cancelled",
        order_status_refunded: "Refunded",
        expired_link_title: "Your link has expired",
        expired_link_description: "Links are only valid for 2 hrs. If you wish to continue to manage your orders, you can request another link."
    },
    order_details: {
        placed_on: "Placed on"
    },
    identity: {
        log_in: "Log In",
        sign_up: "Sign Up",
        sign_out: "Sign Out",
        go_back: "Return to Checkout"
    },
    epw: {
        buy_now: "Buy Now",
        sold_out: "Sold Out"
    },
    ccw: {
        buy_now: "Shop Now",
        sold_out: "Sold Out"
    }
};
